define("discourse/plugins/swapd-mlm/discourse/components/user-earnings-edit-form", ["exports", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["earnings-edit-form"],
    valueCopy: null,
    didInsertElement() {
      this.set("valueCopy", this.value);
    },
    saveDisabled(disabled, formatedValue) {
      return disabled || isNaN(formatedValue);
    },
    formatedValue(value) {
      const floatValue = parseFloat(value || 0);
      return floatValue.toFixed(2);
    },
    actions: {
      doSave() {
        if (this.get("saveDisabled")) return;
        this.save(this.get("formatedValue"));
      },
      doCancel() {
        if (this.get("disabled")) return;
        this.set("marker", false);
      }
    }
  }, [["method", "saveDisabled", [(0, _decorators.default)("disabled", "formatedValue")]], ["method", "formatedValue", [(0, _decorators.default)("valueCopy")]]]));
});