define("discourse/plugins/swapd-mlm/discourse/initializers/referral", ["exports", "discourse/lib/plugin-api", "discourse/controllers/user-invited-show", "discourse/controllers/user-invited", "discourse-common/utils/decorators", "discourse-common/lib/icon-library", "discourse/lib/copy-text", "@ember/controller"], function (_exports, _pluginApi, _userInvitedShow, _userInvited, _decorators, _iconLibrary, _copyText, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initPlugin(api) {
    // api.modifyClass("route:user-invited-show", {
    //   afterModel(model) {
    //     if (this.inviteFilter == "earnings") {
    //       return this.replaceWith("userInvited.show", "redeemed");
    //     }

    //     return this._super(...arguments);
    //   },
    // });

    _userInvited.default.reopen(dt7948.p({
      user: null,
      showRefCopied: false,
      canSeeEarnings(viewingSelf, staff, pluginEnabled) {
        return (viewingSelf || staff) && pluginEnabled;
      },
      viewingSelf(userId, currentUserId) {
        return userId == currentUserId;
      },
      refUrl(userId) {
        return `${window.location.origin}/ref/${userId}`;
      },
      actions: {
        copyRefUrl() {
          const text = this.get("refUrl");
          const copyRange = $('<p id="copy-range"></p>');
          copyRange.html(text.trim().replace("\n", "<br>"));
          $(document.body).append(copyRange);
          (0, _copyText.default)(text, copyRange[0]);
          copyRange.remove();
          this.set("showRefCopied", true);
          setTimeout(() => {
            this.set("showRefCopied", false);
          }, 1500);
        }
      }
    }, [["method", "canSeeEarnings", [(0, _decorators.default)("viewingSelf", "currentUser.staff", "siteSettings.referral_enabled")]], ["method", "viewingSelf", [(0, _decorators.default)("user.id", "currentUser.id")]], ["method", "refUrl", [(0, _decorators.default)("user.id")]]]));
    _userInvitedShow.default.reopen(dt7948.p({
      parent: (0, _controller.inject)('user.invited'),
      userChanged() {
        this.get('parent').set('user', this.user);
      },
      showEarnings(filter, canSeeEarnings) {
        return ["earnings"].includes(filter) && canSeeEarnings;
      },
      viewingSelf(userId, currentUserId) {
        return userId == currentUserId;
      },
      canSeeEarnings(viewingSelf, staff, pluginEnabled) {
        return (viewingSelf || staff) && pluginEnabled;
      }
    }, [["method", "userChanged", [(0, _decorators.observes)("user.id")]], ["method", "showEarnings", [(0, _decorators.default)("filter", "canSeeEarnings")]], ["method", "viewingSelf", [(0, _decorators.default)("user.id", "currentUser.id")]], ["method", "canSeeEarnings", [(0, _decorators.default)("viewingSelf", "currentUser.staff", "siteSettings.referral_enabled")]]]));
  }
  var _default = _exports.default = {
    name: "referral",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.referral_enabled) {
        (0, _pluginApi.withPluginApi)("0.8", initPlugin);
      }
      (0, _iconLibrary.replaceIcon)("notification.referral.notification", "dollar-sign");
      (0, _iconLibrary.replaceIcon)("notification.referral.new_referent", "user-plus");
    }
  };
});