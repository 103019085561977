define("discourse/plugins/swapd-mlm/discourse/components/user-earnings", ["exports", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/lib/url", "@ember/utils", "@ember/object/computed", "@ember/component", "discourse/plugins/swapd-mlm/discourse/components/modal/earnings-edit-transaction", "discourse-common/lib/get-owner"], function (_exports, _ajax, _decorators, _ajaxError, _url, _utils, _computed, _component, _earningsEditTransaction, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["earnings"],
    currentEarnings: 0,
    lifetimeEarnings: 0,
    editingCE: false,
    editingLE: false,
    loadingMore: false,
    canLoadMore: true,
    earnings: [],
    sortingKeys: ["id:desc"],
    sortedEarnings: (0, _computed.sort)("earnings", "sortingKeys"),
    updatingEarnings: false,
    requestingPayout: false,
    didInsertElement() {
      this._super(...arguments);
      this.set("earnings", []);
      this._loadMore();
      const updateEarnings = data => {
        this.setProperties({
          currentEarnings: data.current,
          lifetimeEarnings: data.lifetime
        });
        this.closeModal();
      };
      if (!this.appEvents.has("earnings:add")) {
        this.addEarningsHandler = data => {
          this.get("earnings").addObject(data.data);
          updateEarnings(data);
        };
        this.appEvents.on("earnings:add", this, this.addEarningsHandler);
      }
      if (!this.appEvents.has("earnings:update")) {
        this.updateEarningsHandler = data => {
          const earning = this.get("earnings").findBy("id", data.data.id);
          this.get("earnings").removeObject(earning);
          this.get("earnings").addObject(data.data);
          updateEarnings(data);
        };
        this.appEvents.on("earnings:update", this, this.updateEarningsHandler);
      }
      if (!this.appEvents.has("earnings:delete")) {
        this.deleteEarningsHandler = data => {
          const earning = this.get("earnings").findBy("id", data.data.id);
          this.get("earnings").removeObject(earning);
          updateEarnings(data);
        };
        this.appEvents.on("earnings:delete", this, this.deleteEarningsHandler);
      }
    },
    willDestroyElement() {
      this.appEvents.off("earnings:add", this, this.addEarningsHandler);
      this.appEvents.off("earnings:update", this, this.updateEarningsHandler);
      this.appEvents.off("earnings:delete", this, this.deleteEarningsHandler);
      this._super(...arguments);
    },
    formattedCEarnings(value) {
      const floatValue = parseFloat(value || 0);
      return floatValue.toFixed(2);
    },
    formattedLTEarnings(value) {
      const floatValue = parseFloat(value || 0);
      return floatValue.toFixed(2);
    },
    filteredEarnings(earnings) {
      return earnings.uniqBy("id");
    },
    payoutDisabled(user, requestingPayout, currentEarnings, payoutMinimum) {
      return currentEarnings < payoutMinimum || (0, _utils.isBlank)(user) || requestingPayout;
    },
    actions: {
      addTransaction() {
        this._showModal({
          type: 1
        });
      },
      addComment() {
        this._showModal({
          type: 2,
          value1: this.currentUser.id
        });
      },
      edit(earning) {
        this._showModal(earning);
      },
      editCE() {
        this.setProperties({
          editingLE: false,
          editingCE: true
        });
      },
      editLE() {
        this.setProperties({
          editingLE: true,
          editingCE: false
        });
      },
      saveCE(val) {
        this._updateEarnings({
          current: val
        });
      },
      saveLE(val) {
        this._updateEarnings({
          lifetime: val
        });
      },
      loadMore() {
        this._loadMore();
      },
      requestPayout() {
        if (this.get("requestingPayout")) return;
        this.set("requestingPayout", true);
        (0, _ajax.ajax)("/earnings/payout", {
          type: "POST",
          data: {
            user_id: this.get("user.id")
          }
        }).then(result => {
          _url.default.routeTo(result.url);
        }).catch(_ajaxError.popupAjaxError).finally(() => this.set("requestingPayout", false));
      }
    },
    _handleEarningsData(result) {
      this.get("earnings").addObjects(result.data);
      this.setProperties({
        canLoadMore: result.can_load_more,
        currentEarnings: result.current,
        lifetimeEarnings: result.lifetime
      });
    },
    _loadMore() {
      if (!this.get("canLoadMore") || this.get("loadingMore")) return;
      this.set("loadingMore", true);
      (0, _ajax.ajax)("/earnings", {
        data: {
          offset: this.get("filteredEarnings").length,
          user_id: this.get("user.id")
        }
      }).then(result => this._handleEarningsData(result)).finally(() => this.set("loadingMore", false));
    },
    _updateEarnings(additional) {
      if (this.get("updatingEarnings")) return;
      this.set("updatingEarnings", true);
      const data = Object.assign({
        user_id: this.get("user.id"),
        current: this.get("currentEarnings"),
        lifetime: this.get("lifetimeEarnings")
      }, additional);
      (0, _ajax.ajax)("/earnings/update_earnings", {
        type: "POST",
        data
      }).then(result => {
        this.setProperties({
          lifetimeEarnings: result.lifetime,
          currentEarnings: result.current,
          editingLE: false,
          editingCE: false
        });
      }).finally(() => this.set("updatingEarnings", false));
    },
    _showModal(model) {
      let modal = (0, _getOwner.getOwner)(this).lookup("service:modal");
      model["user_id"] = this.get("user.id");
      modal.show(_earningsEditTransaction.default, {
        model: model
      });
    }
  }, [["method", "formattedCEarnings", [(0, _decorators.default)("currentEarnings")]], ["method", "formattedLTEarnings", [(0, _decorators.default)("lifetimeEarnings")]], ["method", "filteredEarnings", [(0, _decorators.default)("sortedEarnings")]], ["method", "payoutDisabled", [(0, _decorators.default)("siteSettings.referral_payout_user", "requestingPayout", "currentEarnings", "siteSettings.referral_payout_minimum")]]]));
});